import { Container, Row, Col } from 'react-bootstrap';
import Image from '../assets/images/section2/1.png';
import ReactPlayer from 'react-player'


const Section2 = () => {
  return (
    <Container className="section-2" id="about">
        
      <Row className="text-center section-header" data-aos="zoom-in">
        <Col xs={12} md={{span: 8, offset: 2}} >
          <h2>Welcome to AlphaLabs</h2>
          <p>When 3 mad scientists come together and start experimenting in symbioses, magical things tend to be created… take a deep dive inside the lab to see what AlphaLabs is all about! </p>
        </Col>
      </Row>

      <Row className="text-center">
        <Col xs={{span: 12, offset: 0}} md={{span: 10, offset: 1}}>
          <div className="player-wrapper">
            <ReactPlayer className="react-player"  width="100%"
            height="100%" url='https://viliusd91.wistia.com/medias/mbgr7r4xbw' />
          </div>
        </Col>
      </Row>

    </Container>
  );
}

export default Section2;