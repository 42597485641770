import { Container, Navbar, Nav } from 'react-bootstrap';
import Logo from '../assets/images/logo/logo.svg';
import TwitterIcon from '../assets/images/icons/twitter.svg';
import DiscordIcon from '../assets/images/icons/discord.svg';
import OpenSeaIcon from '../assets/images/icons/opensea.svg';

const Navigation = () => {
  return (
    <Navbar fixed="top" collapseOnSelect expand="lg">
        <Container>
        <Navbar.Brand href="#"><img
          alt="Logo"
          src={Logo}
          width="250"
          className="d-inline-block align-top"
        /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://mint.alphalabs.one/" target="_blank" rel="noreferrer">Minting</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#perks">Perks</Nav.Link>
            <Nav.Link href="#roadmap">Roadmap</Nav.Link>
            <Nav.Link href="#team">Team</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="https://twitter.com/AlphaLabsOne" target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="Twitter" /></Nav.Link>
            <Nav.Link href="https://discord.gg/dMpd6PWEYA" target="_blank" rel="noreferrer"><img src={DiscordIcon} alt="Discord" /></Nav.Link>
            <Nav.Link href="https://opensea.io/collection/alphalabs-genesis" target="_blank" rel="noreferrer"><img src={OpenSeaIcon} alt="OpenSea" /></Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Navigation;