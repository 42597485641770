import { Container, Row, Col } from 'react-bootstrap';
import Image1 from '../assets/images/section3/1.png';
import Image2 from '../assets/images/section3/2.png';

const Section3 = () => {
  return (
    <Container className="section-3" id="about">

      <Row className="text-center section-header">
        <Col xs={12} md={{span: 8, offset: 2}} >
          <h2>EXCLUSIVE BENEFITS</h2>
        </Col>
      </Row>

      <Row>
        <Col>
          <img className="d-none d-md-block" src={Image1} alt="Benefits" data-aos="zoom-in"/>
          <img className="d-md-none" src={Image2} alt="Benefits" data-aos="zoom-in"/>
        </Col>
      </Row>
      
    </Container>
  );
}

export default Section3;