import { Container, Row, Col } from 'react-bootstrap';
import Image1 from '../assets/images/section6/1.png';
import Image2 from '../assets/images/section6/2.png';
import Image3 from '../assets/images/section6/3LZUdWP.png';
import TwitterIcon from '../assets/images/icons/twitter.svg';
import InstagramIcon from '../assets/images/icons/instagram.svg';
import YoutubeIcon from '../assets/images/icons/youtube.svg';

const Section6 = () => {
  return (
    <Container className="section-6" id="team">

      <Row className="text-center section-header">
        <Col xs={12} md={{span: 8, offset: 2}} >
          <h2>Team</h2>
          <p>The founding fathers and the three mad scientists behind the newest NFT ecosystem: AlphaLabs.</p>
        </Col>
      </Row>
      
      <Row>

        <Col md={4} xs={12} className="text-center">
          <img src={Image1} alt="Vilius" className="pfp"/>
          <h5>Vilius</h5>
          <p>Co-Founder</p>
        </Col>

        <Col md={4} xs={12} className="text-center">
          <img src={Image3} alt="Felix" className="pfp"/>
          <h5>Felix</h5>
          <p>Co-Founder</p>
        </Col>

        <Col md={4} xs={12} className="text-center">
          <img src={Image2} alt="Chris" className="pfp"/>
          <h5>Chris</h5>
          <p>Co-Founder</p>
        </Col>

      </Row>

    </Container>
  );
}

export default Section6;