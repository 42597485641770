import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import Navigation from './Navigation';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Footer from './Footer';

const App = () => {


  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    document.body.style.overflow = "hidden";
    AOS.init({
      duration : 2000
    });
  }, []);

  return (
    <div className="App">
      <Navigation></Navigation>
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
      <Section6></Section6>
      <Footer></Footer>
    </div>
  );
}

export default App;
