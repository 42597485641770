import { Container, Row, Col } from 'react-bootstrap';
import Image1 from '../assets/images/section4/1.svg';
import Image2 from '../assets/images/section4/2.svg';
import Image3 from '../assets/images/section4/3.svg';
import Image4 from '../assets/images/section4/4.svg';
import Image5 from '../assets/images/section4/5.svg';

const Section4 = () => {
  return (
    <Container className="section-4" id="perks">

      <Row className="d-flex h-100">
        <Col xs={12} className="justify-content-center align-self-center d-md-none text-center" data-aos="zoom-in">
          <img src={Image1} alt="Lock"/>
        </Col>
        <Col xs={12} md={8} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <h3>Guaranteed Whitelist Spots</h3>
          <p>Minters of the AlphaLabs Pass will receive at least 1 guaranteed whitelist spot! Yes you heard that right - GUARANTEED. We know how hard it can be to receive WL spots, especially for highly in demand NFT projects! Because of that, we decided that everyone should get their hands on WL spots with the AlphaLabs Pass. With the combined reach of 3 Influencers in the NFT space you have much higher chances of obtaining WL spots for your favourite project!</p>
        </Col>
        <Col xs={4} className="justify-content-center align-self-center text-end d-none d-md-block" data-aos="zoom-in">
          <img src={Image1} alt="Lock"/>
        </Col>
      </Row>
 
      <Row className="d-flex h-100">
        <Col xs={12} md={4} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <img src={Image2} alt="Gas"/>
        </Col>
        <Col xs={12} md={8} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <h3>First Gas Free Mintpass</h3>
          <p>The NFT space is already an expensive space - especially when it comes to trading NFTs on Ethereum. We want to offer you as many benefits as possible and therefore we don’t want you to spend any more of your hard earned money, than you actually need to. That's why we decided that we will cover the entire gas fees for all of you - hence bringing you the first ever GAS-FREE DROP!</p>
        </Col>
      </Row>  

      <Row className="d-flex h-100">
        <Col xs={12} className="justify-content-center align-self-center d-md-none text-center" data-aos="zoom-in">
          <img src={Image3} alt="Safe"/>
        </Col>
        <Col xs={12} md={8} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <h3>DAO INVESTMENT FUND</h3>
          <p>Together as a community, we will use our community fund, which will be funded by 50% of total secondary market sales royalties - yes, half of all royalties - to do joint investments and decide on the future development of AlphaLabs. These could be fe. (sweeping up bluechip NFTs, creating community funded real-life utility projects, buying old inactive projects, and bringing them back to life or supporting community-voted charities)</p>
        </Col>
        <Col xs={4} className="justify-content-center align-self-center text-end d-none d-md-block" data-aos="zoom-in">
          <img src={Image3} alt="Safe"/>
        </Col>
      </Row> 

      <Row className="d-flex h-100">
        <Col xs={12} md={4} className="justify-content-center align-self-center text-md-start text-center" data-aos="zoom-in">
          <img src={Image4} alt="Coin"/>
        </Col>
        <Col xs={12} md={8} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <h3>Seed Investor Club</h3>
          <p>The limited AlphaLabs Pass is only the beginning of a large ever-growing ecosystem. All of us are passionate about creating immense value, projects or systems and contributing to the  future growth and expansion of the NFT space. We want to reward our early supporters with special discounts for hand-picked projects which won’t be available for anybody else! And who knows, maybe there is already something right around the corner...</p>
        </Col>
      </Row>

      <Row className="d-flex h-100">
        <Col xs={12} className="justify-content-center align-self-center d-md-none text-center" data-aos="zoom-in">
          <img src={Image5} alt="Microphone"/>
        </Col>
        <Col xs={12} md={8} className="justify-content-center align-self-center text-center text-md-start" data-aos="zoom-in">
          <h3>AMA with Experts</h3>
          <p>Thanks to our longstanding experience and range in different business sectors, we want to host AMAs with handpicked experts in the NFT space, as well as bring on various experts from the VC/SAAS/ECOM/CRYPTO sectors. Our goal is to have high quality conversations, provide unique learning and growth opportunities, find new trends and confluence in different topics where we as a DAO can share and develop new ideas together in our lab!</p>
        </Col>
        <Col xs={4} className="justify-content-center align-self-center text-end d-none d-md-block" data-aos="zoom-in">
          <img src={Image5} alt="Microphone"/>
        </Col>
      </Row>

    </Container>
  );
}

export default Section4;