import { Container, Row, Col, Accordion } from 'react-bootstrap';

const Section5 = () => {
  return (
    <Container fluid  className="section-5" id="roadmap">
      <Container>
        
        <Row className="text-center section-header">
          <Col xs={12} md={{span: 8, offset: 2}} >
            <h2>Roadmap</h2>
            <p>Something is brewing in the lab...<br />AlphaLabs has just been created, this is just the start! We want to breach beyond the beliefs of what's possible within one single NFT ecosystem, which  will co-develop with you - our scientists!</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={8}>
            <Row>
              <Col>
               <h3><span class="dot"></span>Mint</h3>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p>First Gas Free Mint</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      We decided that we will cover the entire gas fees for you!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <p>Whitelist + NFT Giveaways</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      We will host regular Giveaways on our Discord Channel!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <p>Guaranteed WL Spot (1x)</p> 
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Holders of the AlphaLabs Pass will receive at least 1 guaranteed whitelist spot!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <p>NFT Expert Trading Calls</p> 
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Handpicked NFT trading experts will share their daily calls with you!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <p>NFT Expert Market Analysis</p> 
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      They will also provide you with regular updates on the NFT market and highlight new trends!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <p>Member of Seed Investor Club</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      We want to reward our early supporters with special discounts for hand-picked projects which won't be available for anybody else!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <p>Early Access to AlphaLabs Ecosystem</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      The limited AlphaLabs Pass is only the beginning of a large ever-growing ecosystem!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3><span class="dot"></span>Upcoming</h3>
                <Accordion>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <p>AMA with Handpicked Experts</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      We want host AMAs with handpicked experts in the NFT space, as well as bring on various experts from the VC/SAAS/ECOM/CRYPTO &#38; NFT.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <p>Exclusive Pass Holder Mint Discounts</p>
                      <p className="status"><span className="done">Done</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      You'll receive exclusive discounts for projects which won't be available for anybody else!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <p>DAO Dashboard Implementation</p>
                      <p className="status"><span className="in-progress">In progress</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      With our DAO Dashboard you'll be able to participate in creating the future of AlphaLabs!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <p>DAO Investment Fund</p>
                      <p className="status"><span className="in-progress">In progress</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      The Community Fund (50% of royalty fees) can be used for various joint investments voted upon by the DAO
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <p>$LABS DAO Token Launch</p>
                      <p className="status"><span className="developing">DEVELOPING</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      The $LABS token will enable further benefits for the whole AlphaLabs ecosystem!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      <p>AlphaLabs NFT PFP Drop</p>
                      <p className="status"><span className="developing">DEVELOPING</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Something is brewing here… Every AlphaLabs Holder will reap enormous benefits and priority access for our future PFP drop. Enjoy the front row seats!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3><span class="dot"></span>Vision</h3>
                <Accordion>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <p>NFT Shark Tank</p>
                      <p className="status"><span className="developing">DEVELOPING</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      Have a great idea? We want to support it! Whether it's by helping with needed funds or bringing in potential Investors, we want to help!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <p>NFT Accelerator</p>
                      <p className="status"><span className="developing">DEVELOPING</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      We want to support good ideas with our own resources (connections, workers etc.) and help you to bring great visions to life!
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      <p>AlphaLabs Academy</p>
                      <p className="status"><span className="developing">DEVELOPING</span></p>
                    </Accordion.Header>
                    <Accordion.Body>
                      The NFT space is a fast developing space. We want to help you guys to understand the fundamentals and stay on track on new opportunities. 
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>

          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Section5;