import { Container } from 'react-bootstrap';
import Video from '../assets/videos/section1/1.mp4';
import { useRef, useEffect } from 'react';

const Section1 = () => {

  const videoRef= useRef();

  useEffect(() => {
    videoRef.current.playbackRate = 0.5;
  }, []);

  const unlockScroll = () => {
    document.body.style.overflow = "visible";
    document.getElementsByClassName('navbar')[0].style.visibility = "visible";
    document.getElementById('about').scrollIntoView();
  }

  return (
    <Container fluid className="section-1" onClick={unlockScroll} id="start">
      <video ref={videoRef} autoPlay muted loop id="preview" src={Video} type="video/mp4" />
    </Container>
  );
}

export default Section1;