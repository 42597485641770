import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../assets/images/logo/logo.svg';
import TwitterIcon from '../assets/images/icons/twitter.svg';
import DiscordIcon from '../assets/images/icons/discord.svg';

const Footer = () => {
  return (
    <Container className="footer">
      
      <Row className="d-flex h-100">
        <Col xs={12} className="justify-content-center align-self-center d-md-none sm-mobile">
          <Row>
            <Col xs={{span: 2, offset: 4}}>
              <a href="https://twitter.com/AlphaLabsOne" target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="Twitter" /></a>
            </Col>
            <Col xs={2}>
             <a href="https://discord.gg/dMpd6PWEYA" target="_blank" rel="noreferrer"><img src={DiscordIcon} alt="Discord" /></a>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={3} className="justify-content-center align-self-center text-center text-md-start">
          <Row>
            <Col>
              <img src={Logo} alt="Logo" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>© 2022 AlphaLabs. All Rights Reserved.</p>
            </Col>
          </Row>
        </Col>
        <Col xs={6} className="justify-content-center align-self-center text-center d-none d-md-block">
          <Row>
            <Col></Col>
            <Col>
              <a href="#about">About</a>
            </Col>
            <Col>
              <a href="#perks">Perks</a>
            </Col>
            <Col>
              <a href="#roadmap">Roadmap</a>
            </Col>
            <Col>
              <a href="#team">Team</a>
            </Col>
            <Col>
              <a href="#faq">FAQ</a>
            </Col>
            <Col></Col>
          </Row>
        </Col>
        <Col xs={3} className="justify-content-center align-self-center">
          <Row>
            <Col xs={{span: 2, offset: 8}}>
              <a href="https://twitter.com/AlphaLabsOne" target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="Twitter" /></a>
            </Col>
            <Col xs={2}>
             <a href="https://discord.gg/dMpd6PWEYA" target="_blank" rel="noreferrer"><img src={DiscordIcon} alt="Discord" /></a>
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
  );
}

export default Footer;